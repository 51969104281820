import * as React from 'react'
import { PageProps, graphql } from 'gatsby'
import styled from 'styled-components'

import LayoutBasic from 'layouts/LayoutBasic'
import Seo from 'components/atoms/Seo'
import Container from 'components/atoms/Container'
import StyledContent from 'components/atoms/StyledContent'

import media from 'styles/media'

const Content = styled.div`
  margin: 96px 0;

  ${media.lg.max} {
    margin: 64px 0;
  }
`

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
`

const Header = styled.div`
  position: relative;
  padding: 0 0 32px 0;
  margin: 0 0 64px 0;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    transform: translateX(-50%);
    width: 124px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary50};
  }

  ${media.lg.max} {
    margin: 0 0 48px 0;
  }
`

const Title = styled.h1`
  font-size: 50px;
  line-height: 62px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ${media.lg.max} {
    font-size: 35px;
    line-height: 43px;
  }
`

const PagePolicyPrivacy: React.FC<
  PageProps<Queries.PagePolicyPrivacyQuery>
> = ({ data }) => {
  const PAGE = data?.wpPage!
  const SEO = data?.wpPage?.seo!

  if (!PAGE || !SEO) throw new Error("CMS data didn't load properly")

  return (
    <LayoutBasic>
      <Seo
        title={SEO?.title!}
        description={SEO?.metaDesc!}
        ogTitle={SEO?.opengraphTitle!}
        ogDescription={SEO?.opengraphDescription!}
        ogImage={SEO?.opengraphImage?.sourceUrl!}
        twitterTitle={SEO?.twitterTitle}
        twitterDescription={SEO?.twitterDescription}
        twitterImage={SEO?.twitterImage?.sourceUrl}
      />
      <Content>
        <Container>
          <ContentInner>
            <Header>
              <Title>{PAGE?.title!}</Title>
            </Header>
            <StyledContent
              dangerouslySetInnerHTML={{ __html: PAGE?.content! }}
            />
          </ContentInner>
        </Container>
      </Content>
    </LayoutBasic>
  )
}

export default PagePolicyPrivacy

export const query = graphql`
  query PagePolicyPrivacy {
    wpPage(slug: { regex: "/polityka-prywatnosci/" }) {
      title
      content
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
